import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import {Sendreq} from '../../req';
import DateRange from '../Helpers/DatePick';
import OpenHours from '../Helpers/DaysHours';
import MainAtractChangeHours from './MainAtractChangeHours'
import TemperoryFixedAtract from './TemperoryFixedAtract';
import DatePicker from "react-datepicker";





export default function(props){





    const {type,MainAtractList}=props;
    const [SelectedOprion,SetSelectedOprion]=useState("34");
    ///const [DaysInWeek,SetDaysInWeek]=useState(array);
    const [startDate, setStartDate] = useState(new Date());

    //const [startDate, setStartDate] = useState(new Date());
    const [FinishDate, SetFinishDate] = useState(new Date(Date.now() + 1*24*60*60*1000));

    const [hoursList,SethoursList]=useState([]);
    const [checkedList,SetcheckedList]=useState([]);

    const [SelectedHour,SetSelectedHour]=useState();
    const [AmountToRemove,SetAmountToRemove]=useState(0);


    useEffect(async()=>{
        const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/ConstraintsMainAtractStock",{"Date":startDate,"AtractID":SelectedOprion});
        SethoursList(SubTemp);



    },[])


    function Checked(hour,index){

        const exist=checkedList.find((x)=>x.index===index);
        if(exist){
            
            SetcheckedList(checkedList.filter(item => item.index !== index));
        }
        else{
            SetcheckedList(checkedList => [...checkedList, {"Atractid":SelectedOprion,"Date":startDate,"Hour":hour,"index":index}]);
        }
        


        //alert(hour + " : " + index);


    }

    async function  GetActiveHours(date){
        setStartDate(date)
        const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/ConstraintsMainAtractStock",{"Date":date,"AtractID":SelectedOprion});
        console.log(SubTemp);
       // setStartDate(date);
        SethoursList(SubTemp)


    }

    
  
  
    function SetDateStart(val){
        setStartDate(val);
    }
    function SetDateFinish(val){
        SetFinishDate(val);
    }





    function SendToapiTypeOne(){
        alert("שלחתי לAPI")
        alert("atrakzia:" +SelectedOprion);
    }
    async function SendToapiTypeTwo(){
        //console.log(checkedList);
       const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/Constraints",{type:"MAINATRACTREMOVESTOCK","selectedHour":SelectedHour,"StockNum":AmountToRemove,"StartDate":startDate,"FinishDate":startDate,"AtractID":SelectedOprion});
        console.log(SubTemp);
        if(SubTemp===true){
           alert("האילוץ התווסף בהצלחה!");
        }
        if(SubTemp==='NOTALLOWDATE'){
           alert("לתאריכים הנל כבר קיימים אילוץ. לא ניתן להכניס כמה אילוצים על תאריך אחד")
        }

    }
    




 

        return (
            <>
                 <h1 class="h1mainatract">בחר אטרקציה</h1>
                                <Form.Select onChange={(e)=>{SetSelectedOprion(e.target.value)}} className="SelectSize">
                                <option value="0">יש לבחור אחת מהאופציות</option>
                                {MainAtractList.map((item,index)=>
                                <option value={item.id}>{item.AtractTitle}</option>
                                
                                
                                )}
                                   
                                </Form.Select>


                               {SelectedOprion !=='0' ? <div className="StatisticDats"><label style={{marginTop:"35px"}}>תאריך מבוקש</label><DatePicker locale="he" className={"form-control"} dateFormat='dd/MM/yyyy' selected={startDate} onChange={(date) => GetActiveHours(date)} /></div> : false}
                                
                               
                      
                            <br /> <br />
                            <label>שעת גריעה</label>
                               <Form.Select onChange={(e)=>{SetSelectedHour(e.target.value)}} className="SelectHourS">
                             
                               {SelectedOprion !=='0' && hoursList.map((item,index)=>
                               <option value={item.Hour} >{item.Hour.substring(0,5)}</option>
                               
                               
                               )}
                                   
                                </Form.Select>
                                <br /> 
                                <label>כמה לגרוע?</label>

                                {SelectedOprion !=='0' ? <Form.Control onChange={(e)=>{SetAmountToRemove(e.target.value)}} value={AmountToRemove} className="NumToRemoveStock" type="number" />: false}





                               

              {/*    {SelectedOprion !=='0' ? <DateRange SetDateFinish={SetDateFinish} SetDateStart={SetDateStart} /> : false}   */}


            {/*       {SelectedOprion !=='0' && DaysInWeek.map((item,index)=>
                                             <OpenHours key={index} num={index} day={item} SetTime={SetTime} />
                                    
                 )} */}



                { SelectedOprion !=='0' ? <Button  onClick={SendToapiTypeTwo} className="SaveAtractbtn d-flex justify-content-center" variant="success">הוספת אילוץ</Button> : false}

    
        
            </>
    
    
         
        );
    



}