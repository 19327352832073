/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Row, Container, Col, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { Sendreq } from "../../req";

/* Amount: "260"
Arrive_Time: "09:00:00"
Client_Name: "יוסי אביטן"
Client_Phone: "0544782814"
CouponUse: "0"
Node: ""
Order_Details: "{\r\n\r\n\t\"Order\":\r\n\t\r\n\t\t\t\t[\r\n\t\t\t\t\t{ \"id\": \"1001\", \"type\": \"Regular\" },\r\n\t\t\t\t\t{ \"id\": \"1002\", \"type\": \"Chocolate\" },\r\n\t\t\t\t\t{ \"id\": \"1003\", \"type\": \"Blueberry\" },\r\n\t\t\t\t\t{ \"id\": \"1004\", \"type\": \"Devil's Food\" }\r\n\t\t\t\t]\r\n}"
Ticket_Amount: "3"
Token: "jhgjygjhgjhgjh"
Transaction_ID: "hjfhgfhgfhggh"
Transaction_Status: "0"
Used: "0"
id: "8" */
export default function (props) {
  const {
    Amount,
    OrderDate,
    Arrive_Time,
    Client_Name,
    Client_Phone,
    Client_Email,
    CouponUse,
    Node,
    Ticket_Amount,
    Token,
    Transaction_ID,
    invoiceTOKEN,
    Transaction_Status,
    Used,
    id,
    Order_Details,
  } = props;
  const [SelfUsed, SetSelfUsed] = useState(Used);
  const [SelfNode, SetSelfNode] = useState(Node);
  const [SelfOrderData, SetSelfOrderData] = useState(JSON.parse(Order_Details));
  const [OpenTab, SetOpenTab] = useState(true);

  function dateformat(date) {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
  }
  useEffect(() => {
    // console.log(SelfOrderData);
  }, [SelfOrderData]);

  function onClickHandler(e) {
    if (OpenTab) {
      const hiddenElement = e.currentTarget.nextSibling;
      hiddenElement.className.indexOf("collapse show") > -1
        ? hiddenElement.classList.remove("show")
        : hiddenElement.classList.add("show");
    }
  }
  async function toggelChange() {
    //SetOpenTab(false)

    var used = "0";
    if (SelfUsed === "0") {
      used = "1";
    }

    const obj = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/UpdateUsed",
      { id: id, value: used }
    );

    if (obj !== false) {
      if (SelfUsed === "1") {
        SetSelfUsed("0");
      } else {
        SetSelfUsed("1");
      }
    }
  }

  async function UpdateNode() {
    const obj = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/UpdateNode",
      { id: id, value: SelfNode }
    );

    if (obj !== false) {
      /// alert("עודכן בהצלחה!");
    }
  }

  function FormFocus() {
    SetOpenTab(false);

    /// alert("Focus!!!");
  }

  function FocusOut() {
    /// alert("leave");
    UpdateNode();
    SetOpenTab(true);
  }

  function SetOpentab() {
    SetOpenTab(true);
  }

  async function Downloadinvoice() {
    return {
      __html:
        '<img className="downloadincvoice" src="download.png"><a href="https://bengurion.wdev.co.il/api/admin/management/getInvoice?token=" target="_blank"></a></img>',
    };
  }
  useEffect(() => {
    // console.log(invoiceTOKEN);
  }, []);

  return (
    <>
      <tr
        className="animate__animated animate__fadeIn"
        onClick={(e) => {
          onClickHandler(e);
        }}
      >
        <td>
          {" "}
          <Form.Check
            type="switch"
            className="OrderSwitch"
            checked={SelfUsed === "1" ? true : false}
            onChange={toggelChange}
          />
        </td>
        <td>{id}</td>
        <td>{dateformat(OrderDate)}</td>
        <td>{Arrive_Time}</td>
        <td>{Client_Name}</td>
        <td>{Client_Phone}</td>
        <td>{Client_Email}</td>
        <td className="Nodetd">
          <Form.Control
            type="text"
            value={SelfNode}
            className="OrderRemarks"
            onChange={(e) => {
              SetSelfNode(e.target.value);
            }}
            onFocus={FormFocus}
            onBlur={FocusOut}
          />
        </td>
        <td>₪{Amount}</td>
        <td>
          <img className="moredataiicone" src="moredata.png" />
        </td>
        <td>
          {Transaction_Status === "2" ? (
            <img src="checked.svg" />
          ) : (
            <img src="canceled.svg" />
          )}
        </td>
      </tr>

      <tr className="collapse ">
        <td colSpan="6">
          <ul>
            <li className="animate__animated animate__fadeIn">
              סה"כ כרטיסים שהוזמנו : {Ticket_Amount}
            </li>
            <li className="animate__animated animate__fadeIn">
              שימוש בקופון : {CouponUse}
            </li>
            {SelfOrderData["MainAtract"].map((item, index) => (
              <li
                className="animate__animated animate__fadeIn"
                key={parseInt(index + item.id)}
              >
                אטרקציה ראשית : {item.CustomerTypeName} כמות : {item.qly} מחיר :
                ₪{item.price} אטרקציה : {item.title}
              </li>
            ))}

            {SelfOrderData["SubAtract"].map((item, index) => (
              <li key={parseInt(index + item.id + item.qly)}>
                אטרקציה משנית : {item.title} כמות : {item.qly} מחיר : ₪
                {item.price}
              </li>
            ))}

            {Transaction_Status === "2" && Amount > 0 && (
              <a
                href={
                  "https://bengurion.wdev.co.il/api/admin/management/getInvoice?token=" +
                  invoiceTOKEN
                }
                target="_blank"
              >
                <img className="downloadincvoice" src="download.png"></img>
              </a>
            )}
          </ul>
        </td>
      </tr>
    </>
  );
}
