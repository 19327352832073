/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, Fragment } from "react";
import {
  Row,
  Container,
  Col,
  InputGroup,
  FormControl,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import loading from "./loading.json";
import Lottie from "lottie-react";
import { useScrollIndicator } from "react-use-scroll-indicator";
import { ReactComponent as Folders } from "./folders.svg";

import Sidebar from "../Component/sidebar";
import TableData from "./Helpers/TableData";
import { Sendreq } from "../req";
import "@inovua/reactdatagrid-community/index.css";
// eslint-disable-next-line import/no-anonymous-default-export
import DatePicker, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he"; // the locale you want
registerLocale("he", he); // register it with the name you want
export const renderRowDetails = ({ data }) => {
  return (
    <div style={{ padding: 20 }}>
      <h3>Row details:</h3>
      <table>
        <tbody>
          {Object.keys(data).map((thisItem, i) => {
            return (
              <tr key={i}>
                <td>סוג מבקר</td>
                <td>{thisItem.CustomerTypeName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const [state] = useScrollIndicator({ onElement: true });

  const [SearchAction, SetSearchAction] = useState("מספר הזמנה");
  const [active, Setactive] = useState(1);
  const [itemPerPage, SetitemPerPage] = useState(8);
  const [OrderObject, SetOrderObject] = useState([]);
  const [BeforeSearchObject, SetBeforeSearchObject] = useState([]);
  const [PageNumber, SetPageNumber] = useState(1);
  const [SearchForm, SetSearchForm] = useState();
  const [newData, setNewData] = useState([]);
  const [filteredSubAtract, setFilteredSubAtract] = useState(false);
  const [filteredDates, setFilteredDates] = useState(false);
  const [checked, setChecked] = useState(false);
  const [filteredByDates, setFilteredByDates] = useState(false);
  const [initData, setInitData] = useState([]);
  const [subSet, setSubSet] = useState([]);
  const [mainSet, setMainSet] = useState([]);
  const [pickedAtract, setPickedAtract] = useState(null);
  const [pickedDates, setPickedDates] = useState({ bool: false, date: null });
  const [dateSet, setdateSet] = useState([]);
  const [dateSetInitial, setdateSetInitial] = useState([]);
  const [isArchive, setIsArchive] = useState(false);
  const [timeSet, setTimeSet] = useState([]);
  const [thisDate, setThisDate] = useState(new Date());
  const [searched, setSearched] = useState(false);
  const [atractName, setAtractName] = useState(null);
  const [pickedDate, setPickedDate] = useState(null);
  const [time, setTime] = useState(null);
  const [totalIncome, setTotalIncome] = useState(0);
  const [allOrdersDateState, setAllOrdersDateState] = useState([]);
  const [lazyCounter, setLazyCounter] = useState(1);
  const [getOrderObjectUrl, setOrderObjectUrl] = useState(
    "https://bengurion.wdev.co.il/api/admin/management/GetAllOrders"
  );
  useEffect(() => {}, [allOrdersDateState]);
  async function GetOrderObject() {
    const obj = await Sendreq(`${getOrderObjectUrl}`, {
      NumOfItems: itemPerPage,
      ActivePage: active,
    });
    const allOrdersData = await Sendreq(`${getOrderObjectUrl}`, {
      NumOfItems: itemPerPage,
      ActivePage: active,
    });
    setAllOrdersDateState(allOrdersData);
    const arr2 = [];
    const subAtractSet = new Set();
    const mainAtractSet = new Set();
    const DatesSet = new Set();
    const DatesSet2 = new Set();
    const TimeSet = new Set();
    let total = [];
    for (let i = 0; i < allOrdersData.length; i++) {
      const thisOrder = allOrdersData[i];
      const dateAsInDb = thisOrder.OrderDate;
      const date = new Date(thisOrder.OrderDate).toLocaleDateString("he-IL");
      const time = thisOrder.Arrive_Time;
      DatesSet.add(date);
      TimeSet.add(time);
      DatesSet2.add(dateAsInDb);
      const { SubAtract, MainAtract } = JSON.parse(thisOrder.Order_Details);
      if (SubAtract.length > 0) {
        // console.log(SubAtract, "SubAtract");
        SubAtract.map((item, i) => {
          const splitIt = item["title"].split("-")[0].toString();
          subAtractSet.add(splitIt);
        });
      }
      if (MainAtract.length > 0) {
        // console.log(MainAtract, "MainAtract");
        MainAtract.map((item, i) => {
          const splitIt = item["title"].split("-")[0].toString();
          mainAtractSet.add(splitIt);
        });
      }
      if (thisOrder.Transaction_Status === "2") {
        arr2.push({
          Amount: thisOrder.Amount,
          Arrive_Time: thisOrder.Arrive_Time,
          Client_Email: thisOrder.Client_Email,
          Client_Name: thisOrder.Client_Name,
          Client_Phone: thisOrder.Client_Phone,
          CouponUse: thisOrder.CouponUse,
          Node: thisOrder.Node,
          OrderDate: thisOrder.OrderDate,
          OrderMade: thisOrder.OrderMade,
          Order_Details_Parsed: { SubAtract, MainAtract },
          Order_Details: thisOrder.Order_Details,
          Ticket_Amount: thisOrder.Ticket_Amount,
          Token: thisOrder.Token,
          Transaction_ID: thisOrder.Transaction_ID,
          Transaction_Status: thisOrder.Transaction_Status,
          Used: thisOrder.Used,
          id: thisOrder.id,
          invoiceTOKEN: thisOrder.invoiceTOKEN,
          smsSend: thisOrder.smsSend,
        });
      }
    }

    var sorted = [...DatesSet]
      .sort(function (a, b) {
        // console.log(a, b, "a,b");
        var c = new Date(a);
        var d = new Date(b);
        return d - c;
      })
      .reverse();
    setdateSet(sorted);
    setdateSetInitial(sorted);

    setTimeSet([...TimeSet]);
    setSubSet([...subAtractSet]);
    setMainSet([...mainAtractSet]);
    const key = "id";
    const arrayUniqueByKey = [
      ...new Map(arr2.map((item) => [item[key], item])).values(),
    ];
    setNewData(arrayUniqueByKey);

    setInitData(arrayUniqueByKey);

    const result = arr2;
    if (obj !== false) {
      SetOrderObject(obj);
      SetBeforeSearchObject(obj);
    }
  }

  useEffect(() => {
    const tempArr = [];
    const DatesSet = new Set();
    let timeSet = new Set();
    const data = initData;
    // TODO: HANDLE IT IN A BETTER WAY

    if (atractName === "סינון לפי שם אטרקציה") {
      setNewData(initData);
      setTime(null);
      setPickedDate(null);
      setdateSet(dateSetInitial);
      return;
    }
    if (atractName === null && pickedDate === null && time === null) {
      return;
    }
    if (atractName !== null && pickedDate === null && time === null) {
      data.forEach((item, i) => {
        item.Order_Details_Parsed.MainAtract.forEach((item2, j) => {
          if (atractName === "סינון לפי שם אטרקציה") {
            setNewData(initData);
            setTime(null);
            setPickedDate(null);
            return;
          }
          if (atractName === "סינון לפי שם אטרקציה") {
            const date = new Date(item.OrderDate).toLocaleDateString("he-IL");
            const thisTime = item.Arrive_Time;
            timeSet.add(thisTime);
            tempArr.push(item);
            DatesSet.add(date);
          }
          if (item2.title.includes(atractName)) {
            const date = new Date(item.OrderDate).toLocaleDateString("he-IL");
            const thisTime = item.Arrive_Time;
            timeSet.add(thisTime);
            tempArr.push(item);
            DatesSet.add(date);
          }
        });
        item.Order_Details_Parsed.SubAtract.forEach((item3, j) => {
          if (item3.title.includes(atractName)) {
            const date = new Date(item.OrderDate).toLocaleDateString("he-IL");

            tempArr.push(item);
            DatesSet.add(date);
          }
        });
      });

      var sorted = [...DatesSet]
        .sort(function (a, b) {
          var c = new Date(a);
          var d = new Date(b);
          return d - c;
        })
        .reverse();
      setdateSet(sorted);
    }
    if (atractName !== null && pickedDate !== null && time === null) {
      data.forEach((item, i) => {
        const temp = new Date(item.OrderDate).toLocaleDateString("he-IL");
        const ariveTime = item.Arrive_Time;
        item.Order_Details_Parsed.MainAtract.forEach((item2, j) => {
          if (
            atractName !== "סינון לפי שם אטרקציה" &&
            item2.title.includes(atractName) &&
            temp === pickedDate
          ) {
            tempArr.push(item);
            timeSet.add(ariveTime);
          }
        });
        item.Order_Details_Parsed.SubAtract.forEach((item3, j) => {
          // console.log(item3, "item3");
          if (item3.title.includes(atractName) && temp === pickedDate) {
            timeSet.add(ariveTime);
            tempArr.push(item);
          }
        });
      });
      setTimeSet([...timeSet]);
    }
    if (atractName !== null && pickedDate !== null && time !== null) {
      data.forEach((item, i) => {
        const temp = new Date(item.OrderDate).toLocaleDateString("he-IL");
        const ariveTime = item.Arrive_Time;
        item.Order_Details_Parsed.MainAtract.forEach((item2, j) => {
          if (
            atractName !== "סינון לפי שם אטרקציה" &&
            item2.title.includes(atractName) &&
            temp === pickedDate &&
            ariveTime === time
          ) {
            tempArr.push(item);
          }
        });
        item.Order_Details_Parsed.SubAtract.forEach((item3, j) => {
          if (
            item3.title.includes(atractName) &&
            temp === pickedDate &&
            ariveTime === time
          ) {
            tempArr.push(item);
          }
        });
      });
    }
    if (atractName === null && pickedDate !== null && time === null) {
      initData.forEach((item, i) => {
        const temp = new Date(item.OrderDate).toLocaleDateString("he-IL");

        if (temp === pickedDate) {
          tempArr.push(item);
        }
      });
    }
    const key = "id";
    const arrayUniqueByKey = [
      ...new Map(tempArr.map((item) => [item[key], item])).values(),
    ];
    setNewData(arrayUniqueByKey);
    const totalIncomeCalc = arrayUniqueByKey.reduce((acc, item) => {
      return acc + parseInt(item.Amount);
    }, 0);
    setTotalIncome(totalIncomeCalc);
  }, [atractName, pickedDate, time]);
  useEffect(() => {}, [pickedDate]);
  function prev() {
    if (active > 1) {
      Setactive(active - 1);
    }
  }

  function next() {
    if (active < PageNumber) {
      Setactive(active + 1);
    }
  }

  async function GetNumberOfPages() {
    const obj = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/GetPagesNum",
      { NumOfItems: itemPerPage }
    );
    /// console.log(obj)
    if (obj !== false) {
      SetPageNumber(obj);
    }
  }
  useEffect(async () => {
    await GetOrderObject();
  }, [active]);

  useEffect(async () => {
    await GetNumberOfPages();
  }, []);

  function pagenumber(number) {
    Setactive(number);
  }

  async function SerchReq(val) {
    // console.log(val);
    SetSearchForm(val);
    if (val !== "") {
      const obj = await Sendreq(
        "https://bengurion.wdev.co.il/api/admin/management/SearchOrders",
        { value: val }
      );
      setSearched(true);
      SetOrderObject(obj);
      SetPageNumber(1);
    } else {
      SetOrderObject(BeforeSearchObject);
      GetNumberOfPages();
      setSearched(false);
    }
  }

  //let active = 2;
  let items = [];
  for (let number = 1; number <= PageNumber; number++) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => {
          pagenumber(number);
        }}
        active={number === active}
      >
        {number}
      </Pagination.Item>
    );
  }

  useState(() => {}, [state]);
  const [progressWidth, setProgressWidth] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);
    };
  }, []);
  //Math.round(progressWidth)
  useEffect(() => {
    if (Math.round(progressWidth) === 100) {
      setLazyCounter(lazyCounter + 1);
    }
  }, [progressWidth]);
  return (
    <div ref={state.targetElement}>
      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="contect">
              <Container>
                <div className="page_heading">
                  <h1>הזמנות</h1>

                  {pickedDate !== null && (
                    <h5>
                      {` ההכנסה היומית בתאריך ${pickedDate} היא:  ${totalIncome}  ₪`}
                    </h5>
                  )}
                </div>
                {/* OrderObject.length !==  */}
                {true && (
                  <div className="TopGrey ">
                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <label
                          style={{
                            textAlign: "right",
                            width: "100%",
                            marginRight: "5px",
                            fontSize: "0.7rem",
                          }}
                        >
                          סינון לפי שם אטרקציה
                        </label>
                        <Form.Select
                          disabled={searched === true ? true : false}
                          onChange={(e) => {
                            setAtractName(e.target.value);
                            setPickedDate(null);
                            // handleFilterByAtrac(e.target.value);
                          }}
                          aria-label="Default select example"
                          value={
                            atractName === null
                              ? "סינון לפי שם אטרקציה"
                              : atractName
                          }
                        >
                          <option key={352334234235232}>
                            סינון לפי שם אטרקציה{" "}
                          </option>
                          <option
                            disabled={true}
                            key={31251263453387812}
                            value="----"
                          >
                            אטרקציה ראשית
                          </option>
                          {mainSet.map((item, index) => {
                            return (
                              <option key={parseInt(index)} value={item}>
                                {item}
                              </option>
                            );
                          })}
                          <option
                            disabled={true}
                            key={513523423514}
                            value="----"
                          >
                            -----
                          </option>
                          <option disabled={true} key={346345345} value="----">
                            אטרקציה משנית
                          </option>
                          {subSet.map((item, index) => {
                            console.log(item, "item id");
                            return (
                              <option key={parseInt(index)} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col md={2}>
                        <label
                          style={{
                            textAlign: "right",
                            width: "100%",
                            marginRight: "5px",
                            fontSize: "0.7rem",
                          }}
                        >
                          סינון לפי תאריך
                        </label>
                        <DatePicker
                          readOnly={searched === true ? true : false}
                          locale="he"
                          dateFormat="dd/MM/yyyy"
                          setDefaultLocale
                          selected={thisDate}
                          filterDate={(date) => {
                            console.log(
                              date.toLocaleDateString("he-IL"),
                              "datesss"
                            );
                            return dateSet.includes(
                              date.toLocaleDateString("he-IL")
                            );
                          }}
                          onChange={(date) => {
                            console.log(
                              date.toLocaleDateString("he-IL"),
                              "dateasdasdasd"
                            );
                            setThisDate(date);
                            setPickedDate(date.toLocaleDateString("he-IL"));
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <label
                          style={{
                            textAlign: "right",
                            width: "100%",
                            marginRight: "5px",
                            fontSize: "0.7rem",
                          }}
                        >
                          סינון לפי שעת הגעה
                        </label>
                        <Form.Select
                          disabled={pickedDate === null ? true : false}
                          onChange={(e) => {
                            setTime(e.target.value);
                          }}
                          aria-label="Default select example"
                        >
                          {timeSet.map((item, index) => {
                            return (
                              <option key={parseInt(index)} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col md={2}>
                        <label
                          style={{
                            textAlign: "right",
                            width: "100%",
                            marginRight: "5px",
                            fontSize: "0.7rem",
                          }}
                        >
                          חיפוש חופשי
                        </label>
                        <InputGroup>
                          <FormControl
                            placeholder=" חיפוש חופשי"
                            onChange={(e) => {
                              SerchReq(e.target.value);
                            }}
                            value={SearchForm}
                          />
                        </InputGroup>
                      </Col>
                      <Col md={2}>
                        <div
                          style={{
                            alignItems: "center",
                            display: "grid",
                            marginTop: "24px",
                            gridTemplateColumns: "auto auto",
                          }}
                        >
                          <button
                            style={{ background: "#45ae4e", color: "#fafafa" }}
                            onClick={() => {
                              setPickedDate(null);
                              setTime(null);
                              setAtractName("סינון לפי שם אטרקציה");
                              setSearched(false);
                              SetSearchForm("");
                              window.location.reload(false);
                            }}
                            className="btn"
                          >
                            נקה
                          </button>
                        </div>
                      </Col>
                      <Col md={1}>
                        <div></div>
                      </Col>
                    </Row>
                  </div>
                )}

                {OrderObject.length === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      height: "60vh",
                      placeItems: "center",
                    }}
                  >
                    <div
                      className=" animate__animated animate__fadeIn"
                      style={{ width: "200px" }}
                    >
                      <h3>מחפש רשומות</h3>
                      <hr />
                      <Lottie
                        width="200px"
                        height={10}
                        animationData={loading}
                        loop={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        textAlign: "right",
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        width: "150px",
                        padding: "1rem 0 0 0",
                      }}
                    >
                      <Form.Check
                        type="switch"
                        className="OrderSwitch"
                        checked={isArchive === true ? true : false}
                        onChange={() => {
                          // SetOrderObject(null);
                          // setNewData(null);
                          setIsArchive((prevState) => !prevState);
                          if (isArchive === true) {
                            setOrderObjectUrl(
                              "https://bengurion.wdev.co.il/api/admin/management/GetAllOrdersArchive"
                            );
                          }
                          if (isArchive === false) {
                            setOrderObjectUrl(
                              "https://bengurion.wdev.co.il/api/admin/management/GetAllOrders"
                            );
                          }
                          GetOrderObject();
                        }}
                      />
                      <label>
                        {isArchive ? "להסתרת ארכיון" : "להצגת ארכיון"}
                      </label>
                    </div>
                    <Table
                      className="OrderTable animate__animated animate__fadeIn"
                      striped
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>מימוש</th>
                          <th>מספר הזמנה</th>
                          <th>תאריך הגעה</th>
                          <th>שעת הגעה</th>
                          <th>שם המזמין</th>
                          <th>נייד המזמין</th>
                          <th>כתובת מייל</th>
                          <th>הערות</th>
                          <th>שולם</th>
                          <th>פירוט הזמנה</th>
                          <th>סטטוס תשלום</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searched &&
                          OrderObject.map((item, index) => {
                            return (
                              <Fragment key={parseInt(item.id + index)}>
                                <TableData
                                  {...item}
                                  key={parseInt(item.id + index)}
                                />
                              </Fragment>
                            );
                          })}
                        {!searched &&
                          newData.map((item, index) => {
                            if (index < 25 * lazyCounter) {
                              return (
                                <Fragment key={parseInt(index)}>
                                  <TableData {...item} key={parseInt(index)} />
                                </Fragment>
                              );
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                )}

                <div></div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
