import React from 'react'
import { useHistory } from "react-router-dom";

 

function Shortcuts() {
  let history = useHistory();
 

  return (
    <div className='shortcut_nav'>

       {/* <div>
          <span>
               קיצורים מהירים
          </span>
        </div>
      <button className='short_btn' type="button" onClick={() => {
        history.push("/Addcoupon")
      }}>
      הוסף קופון
    </button> 
     */}

    </div>
  )
}

export default Shortcuts