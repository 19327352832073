import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Button, Row, Container, Col, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";
import { Sendreq } from "../req";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import Sidebar from "./sidebar";
registerLocale("he", he);

export default function Closedates(props) {
  const trypo = props.connectedobj;
  const [Isconnected, SetIsconnected] = useState(false);
  const [Islogin, SetIslogin] = useState(true);
  const [Dataobject, SetDataobject] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [FinishDate, SetFinishDate] = useState(new Date());
  const [ClosingReason, SetClosingReason] = useState("");

  useEffect(async () => {
    SetIslogin(true);
    const closingdates = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/GetConstraints",
      []
    );
    console.log(closingdates);
    SetIslogin(false);
    console.log(closingdates);
    if (closingdates === false) {
      alert("שגיאה בטעינת העמוד");
    } else {
      SetDataobject(closingdates);
    }
    //console.log(closingdates)
    console.log(Dataobject);
  }, Dataobject);

  if (Islogin) {
    return <h1>טוען...</h1>;
  }

  function ConvertName(str) {
    if (str === "SUBCLOSE") {
      return "סגירת אטרקציה משנית - קבועה";
    }

    if (str === "STARTTEMPORARY") {
      return "הפעלת אטרקציה זמנית - לא קבועה";
    }
    if (str === "MAINNEWOPENTIME") {
      return "שינוי שעות פעילות - אטרקציה ראשית";
    }
    if (str === "STEADYSUBATRACT") {
      return "שינוי שעות פעילות - אטרקציה זמנית קבועה";
    }
    if (str === "SUBATRACTSTOCK") {
      return "סגירת מלאי אטרקציה משנית";
    }
    if (str === "CLOSESTOCKMAIM") {
      return "סגירת סטוק אטרקציה ראשית";
    }
  }

  async function removefromlist(objectid) {
    const closingdates = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/DelConstraint",
      { id: objectid }
    );
    if (closingdates === true) {
      //alert("true");
      SetDataobject(Dataobject.filter((item, i) => item.id !== objectid));
      //console.log("")
    }
  }

  function dateformat(date) {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
  }

  return (
    <>
      {console.log(trypo.data)}

      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="page_heading">
              <h1> רשימת אילוצים קיימים</h1>
            </div>
            <div className="contect">
              <div className="mainatractContect">
                <Container>
                  <Col md={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>מחיקה</th>
                          <th>שם אטרקציה</th>
                          <th>ת. תחילת אילוץ</th>
                          <th>ת. סיום אילוץ</th>
                          <th>סוג אילוץ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Dataobject &&
                          Dataobject.map((obj) => (
                            <tr>
                              <td className="Pointer" style={{ color: "red" }}>
                                <a
                                  onClick={() => {
                                    removefromlist(obj.id);
                                  }}
                                >
                                  מחיקה
                                </a>
                              </td>
                              <td>{obj.AtractTitle}</td>
                              <td>{dateformat(obj.StartDate)}</td>
                              <td>{dateformat(obj.FinishDate)}</td>
                              <td>{ConvertName(obj.Type)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
