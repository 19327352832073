import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import Spinner from './Spinner.gif'
import { Verify } from '../verifysystem';
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ component: Component, ...rest }) {
    const [isAuth, setisAuth] = useState(null);
    const [ConnectedData, SetConnectedData] = useState({});

    useEffect(() => {
        async function checkconections() {
            const status = await Verify();
            if (status.status) {
                setisAuth(true);
                SetConnectedData(status.obj);
            }
            else {
                setisAuth(false);
            }
        }
        if (localStorage.getItem('token') != null) {
            checkconections();
        }
        else
            setisAuth(false);
    }, [])
    if (isAuth === null) {
        return (
            <div className="splash animate__fadeIn">
                <img src={Spinner} alt="loading..." />
            </div>
        );
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth) {
                    return <Component connectedobj={ConnectedData} />
                } else {
                    return (<Redirect to={{ pathname: '/login' }} />
                    );
                }
            }} />
    );
}