import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,Table} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected';
import {Sendreq} from '../req'


export default function CustomersType(props){


    const trypo=props.connectedobj
    const [Isconnected,SetIsconnected]=useState(false);
    const [Islogin,SetIslogin]=useState(true);
    const [Dataobject,SetDataobject]=useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [ClosingReason,SetClosingReason]=useState('');


    useEffect(async ()=>{
        SetIslogin(true)
        const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/returnclosingdays",[]);

        SetIslogin(false)
        console.log(closingdates)
        if(closingdates===false )
        {
            alert("שגיאה בטעינת העמוד")
        }
        else{
            SetDataobject(closingdates)
        }
        //console.log(closingdates)

    },Dataobject)




if(Islogin){
    return (
        <h1>טוען...</h1>
    );
}

async function addtolist(){


   ///
   const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/addclosedate",{Date:startDate,Reasone:ClosingReason});
   if(closingdates!==false){
    SetDataobject(Dataobject => [...Dataobject, closingdates]);

   }
    console.log(closingdates)

}

async function removefromlist(objectid){
  const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/removefromclosedatelist",{id:objectid});
  if(closingdates===true){
    SetDataobject(Dataobject.filter((item, i) => item.id !== objectid));


  }

}

    return (
      <>


<Container>
    <Row>
    <Col md={4}>
    <Form.Group controlId="closing">
      <Form.Label>סוג לקוח</Form.Label>
      <Form.Control value={ClosingReason} onChange={(e)=>{SetClosingReason(e.target.value)}} type="text" placeholder="סוג לקוח" />
    </Form.Group>
    </Col>
    <Col md={4}>
    <Button onClick={addtolist} style={{marginTop:"30px"}} variant="primary" type="submit">
    הוסף סוג לקוח
  </Button>
  </Col>
    </Row>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>#</th>
      <th>סוג לקוח</th>
    </tr>
  </thead>
  <tbody>
 {Dataobject && Dataobject.map((obj) =>
<tr>
<td style={{color:"red"}}><a onClick={()=>{removefromlist(obj.id)}}>מחיקה</a></td>
<td>{obj.Closing_Reason}</td>
</tr>

)}

  </tbody>
</Table>

</Container>
     </>
    );

}

