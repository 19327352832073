import React from "react";

import { useState, useEffect } from "react";
import { ReactComponent as OfficeIcon } from "./office.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as MainAtrac } from "./mainAtrac.svg";
import { ReactComponent as SubAtrac } from "./subAtrac.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Ilutz } from "./ilutz.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as Stats } from "./stats.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Table } from "./table.svg";
import { ReactComponent as Entry } from "./entry.svg";

import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";

export default function (props) {
  // let { id } = useParams();
  let location = useLocation();
  let { atractType } = useParams();
  useEffect(() => {
    console.log(location);
  }, [location]);
  return (
    <div className="sidebar">
      {location.pathname === "/login" || location.pathname === "/Login" ? (
        <>
          <div style={{ padding: "60px", margin: "0 auto" }}>
            <img alt="bg-logo-test" src="bengutionlogo.png"></img>
          </div>
        </>
      ) : (
        <Router>
          <nav>
            <ul>
              <div style={{ paddingRight: "50px" }}>
                <img alt="bg-logo-test" src="bengutionlogo.png"></img>
              </div>
              <li>
                <div>
                  <a
                    className={
                      location.pathname === "/dashboard" ||
                      location.pathname === "/dashboard"
                        ? "active"
                        : "idle"
                    }
                    href="/dashboard"
                  >
                    <HomeIcon /> <span className="boldli">דף בית</span>
                  </a>
                </div>
              </li>

              <li>
                <a
                  className={
                    location.pathname === "/Welcome" ||
                    location.pathname === "/Welcome"
                      ? "active"
                      : "idle"
                  }
                  href="/Welcome"
                >
                  <div>
                    <OfficeIcon />{" "}
                    <span className="boldli"> עריכת עמוד בית </span>
                  </div>
                </a>
              </li>

              <li className="boldli">
                <a
                  className={
                    (location.pathname == "/Mainattraction" &&
                      atractType === "1") ||
                    location.pathname === "/Mainattraction?atractType=1"
                      ? "active"
                      : "idle"
                  }
                  href="/Mainattraction?atractType=1"
                >
                  <div>
                    <span className="boldli">
                      {" "}
                      <MainAtrac style={{ width: "20px" }} /> אטרקציה ראשית -
                      צריף{" "}
                    </span>
                  </div>
                </a>
              </li>

              <li>
                <a
                  className={
                    location.pathname === "/SubAttracrionList?atractType=1" ||
                    location.pathname === "/SubAttracrionList?atractType=1"
                      ? "active"
                      : "idle"
                  }
                  href="/SubAttracrionList?atractType=1"
                >
                  <div className="boldli">
                    <SubAtrac style={{ width: "20px", height: "20px" }} />
                    {"  "}
                    <span className="boldli">אטרקציות משניות</span>
                  </div>
                </a>
              </li>

              <li>
                <span className="boldli">
                  <Plus style={{ width: "20px", height: "20px" }} />
                  {"  "}
                  <a
                    className={
                      location.pathname === "/Addsubatraction" ||
                      location.pathname === "/Addsubatraction"
                        ? "active"
                        : "idle"
                    }
                    href="/Addsubatraction"
                  >
                    הוספת אטרקציה משנית
                  </a>
                </span>
              </li>

              <li className="boldli">
                <a
                  className={
                    location.pathname === "/Mainattraction?atractType=2" ||
                    location.pathname === "/Mainattraction?atractType=2"
                      ? "active"
                      : "idle"
                  }
                  href="/Mainattraction?atractType=2"
                >
                  <MainAtrac style={{ width: "20px", height: "20px" }} />{" "}
                  <span className="boldli">אטרקציה ראשית במכון</span>
                </a>
              </li>

              <li className="boldli">
                <a
                  className={
                    location.pathname === "/Constraints" ||
                    location.pathname === "/Constraints"
                      ? "active"
                      : "idle"
                  }
                  href="/Constraints"
                >
                  <Ilutz style={{ width: "20px", height: "20px" }} />{" "}
                  <span className="boldli">אילוצים</span>
                </a>
              </li>
              <li>
                <span className="boldli">
                  <List style={{ width: "20px", height: "20px" }} />{" "}
                  <a
                    className={
                      location.pathname === "/ConstraintsList" ||
                      location.pathname === "/ConstraintsList"
                        ? "active"
                        : "idle"
                    }
                    href="/ConstraintsList"
                  >
                    רשימת אילוצים
                  </a>
                </span>
              </li>

              <li>
                <a
                  className={
                    location.pathname === "/Orders" ||
                    location.pathname === "/Orders"
                      ? "active"
                      : "idle"
                  }
                  href="/Orders"
                >
                  <Table style={{ width: "20px", height: "20px" }} />{" "}
                  <span className="boldli">הזמנות</span>
                </a>
              </li>

              <li>
                <a
                  className={
                    location.pathname === "/closedates" ||
                    location.pathname === "/closedates"
                      ? "active"
                      : "idle"
                  }
                  href="/closedates"
                >
                  <Entry style={{ width: "20px", height: "20px" }} />{" "}
                  <span className="boldli">סגירת צריף</span>
                </a>
              </li>

              <li>
                <a
                  className={
                    location.pathname === "/Addcoupon" ||
                    location.pathname === "/Addcoupon"
                      ? "active"
                      : "idle"
                  }
                  href="/Addcoupon"
                >
                  <Tick style={{ width: "20px", height: "20px" }} />{" "}
                  <span className="boldli">הוסף קופון</span>
                </a>
              </li>

              {/* <li>
                <a href="/Statistic">
                  <div>
                    <span className="boldli">
                      <Stats style={{ width: "20px", height: "20px" }} />{" "}
                      סטטיסטיקות
                    </span>
                  </div>
                </a>
              </li> */}
            </ul>
          </nav>
        </Router>
      )}

      {/* <img id="stickylogo" src="sidebar.png" /> */}
    </div>
  );
}
