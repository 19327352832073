import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Row,
  Container,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import Sidebar from "./sidebar";
import { Sendreq } from "../req";
import "react-datepicker/dist/react-datepicker.css";

const Welcome = (props) => {
  const [WelcomeTitle, SetWelcomeTitle] = useState("");
  const [Description, SetDescription] = useState("");
  const [Terms, SetTerms] = useState("");
  const [Message, SetMessage] = useState("");
  const [fetching, SetFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const obj = await Sendreq(
        "https://bengurion.wdev.co.il/api/admin/Management/Welcome",
        {}
      );
      SetWelcomeTitle(obj[0]["Title"]);
      SetDescription(obj[0]["Contect"]);
      SetTerms(obj[0]["Terms"]);
      SetMessage(obj[0]["message"]);
    };
    fetchData();
  }, []);

  async function SendToApi() {
    SetFetching(true);
    const obj = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/Management/WelcomeUpdate",
      {
        Title: WelcomeTitle,
        Contect: Description,
        Terms: Terms,
        message: Message,
      }
    );
    if (obj !== false) {
      alert("הפרטים עודכנו בהצלחה");
      SetFetching(false);
    }
    SetFetching(false);
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="page_heading">
              <h1>תוכן עמוד ראשי - ברוכים הבאים</h1>
            </div>
            <div className="mainatractContect">
              <Container>
                <div class="contect">
                  <h1 class="h1mainatract WelcomePageTitle">
                    כותרת - ברוך הבא
                  </h1>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="כותרת ברוך הבא"
                    className="CenterContect atractinput1"
                  >
                    <Form.Control
                      value={WelcomeTitle}
                      onChange={(e) => {
                        SetWelcomeTitle(e.target.value);
                      }}
                      type="text"
                    />
                  </FloatingLabel>
                  <h1 class="h1mainatract WelcomePageTitle">תוכן - ברוך הבא</h1>
                  <Form.Control
                    onChange={(e) => {
                      SetDescription(e.target.value);
                    }}
                    value={Description}
                    as="textarea"
                    placeholder="תוכן ברוך הבא"
                    className=" atractinput1 CenterContect"
                    style={{ height: "200px" }}
                  />
                  <h1 class="h1mainatract WelcomePageTitle">
                    הודעה לקהל (קופונים בדרך כלל){" "}
                  </h1>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="הודעה"
                    className="CenterContect atractinput1"
                  >
                    <Form.Control
                      value={Message}
                      onChange={(e) => {
                        SetMessage(e.target.value);
                      }}
                      type="text"
                    />
                  </FloatingLabel>
                  <h1 class="h1mainatract WelcomePageTitle">
                    תנאי שימוש עבור רכישה
                  </h1>
                  <Form.Control
                    onChange={(e) => {
                      SetTerms(e.target.value);
                    }}
                    value={Terms}
                    as="textarea"
                    placeholder="תוכן ברוך הבא"
                    className=" atractinput1 CenterContect"
                    style={{ height: "200px" }}
                  />
                </div>
                <Button
                  disabled={fetching}
                  onClick={SendToApi}
                  className="SaveAtractbtn d-flex justify-content-center"
                  variant="success"
                >
                  שמירה
                </Button>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Welcome;
