import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";
import Sidebar from "../Component/sidebar";
import DashboardStatistic from "../Component/Helpers/dashboardStatistic";
import Forms from "../Component/Helpers/forms";
import CouponList from "../Component/Helpers/CouponList";
import { Sendreq } from "../req";
import Activitytime from "../Component/Constraints/Activitytime";

export default function (props) {
  const [SelectedOption, SetSelectedOption] = useState(0);

  async function insertCoupon() {
    const AddCoupon = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/AddCoupon",
      {}
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="page_heading">
              <h1> אילוצים</h1>
            </div>
            <div className="mainatractContect">
              <Container>
                <h1 class="h1mainatract">בחירת סוג אילוץ</h1>
                <Form.Select
                  onChange={(e) => {
                    SetSelectedOption(e.target.value);
                  }}
                  className="SelectSize"
                >
                  <option value="0">יש לבחור אחת מהאופציות</option>
                  <option value="1"> אטרקציה ראשית - שינוי שעות פעליות</option>
                  <option value="2">
                    אטרקציה משנית -קבועה - שינוי שעות פעילות
                  </option>
                  <option value="3">סגירת אטרקציה משנית - קבועה</option>
                  <option value="4">הפעלת אטרקציה זמנית</option>
                  <option value="5">סגירת מלאי אטרקציה משנית </option>
                  <option value="6">סגירת מלאי אטרקציה ראשית </option>
                  <option value="7">גריעת מלאי מספרי מאטרקציה ראשית </option>
                </Form.Select>

                {SelectedOption === "1" ? <Activitytime type={"1"} /> : false}
                {SelectedOption === "3" ? <Activitytime type={"3"} /> : false}
                {SelectedOption === "2" ? <Activitytime type={"2"} /> : false}
                {SelectedOption === "4" ? <Activitytime type={"4"} /> : false}
                {SelectedOption === "5" ? <Activitytime type={"5"} /> : false}
                {SelectedOption === "6" ? <Activitytime type={"6"} /> : false}
                {SelectedOption === "7" ? <Activitytime type={"7"} /> : false}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
