import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  Button,
  Row,
  Container,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import Sidebar from "./sidebar";
import Forms from "./Helpers/forms";
import OpenHours from "./Helpers/DaysHours";
import { Sendreq, SendFile } from "../req";
import SwitchToggle from "react-switch";
import VisitorsTypes from "./Helpers/VisitorsTypes";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import SubAtractStatistic from "../Component/Helpers/SubAtractStatistic";

registerLocale("he", he);

export default function (props) {
  const [startDate, setStartDate] = useState(new Date());
  const [StatisticArray, SetStatisticArray] = useState([]);

  function dateformat(date) {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
  }

  useEffect(async () => {
    const Dates = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/GetStatistic",
      { Date: startDate }
    );
    SetStatisticArray(Dates);
    console.log(Dates);
  }, [startDate]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="mainatractContect">
              <Container>
                <div class="contect">
                  <h1>גריעת מלאי</h1>

                  <div className="StatisticDats">
                    <label style={{ marginBottom: "10px" }}>תאריך מבוקש</label>
                    <DatePicker
                      locale="he"
                      className={"form-control"}
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <Row>
                    {StatisticArray.map((item, index) => (
                      <Col md={4}>
                        <SubAtractStatistic
                          Title={item.SunAtractTitle}
                          AvlStock={item.AvlStoc}
                          UseStock={item.UsingStock}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
