import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";

export default function Login(props) {
  const { ismovie } = props;
  const [username, Setusername] = useState("");
  const [password, Setpassword] = useState("");
  const [Connected, SetConnected] = useState(false);
  const [Isconnected, SetIsconnected] = useState(false);
  let history = useHistory();

  useEffect(async () => {
    var connected = await isconnected();
    if (connected) {
      history.push("/dashboard");
    }
  }, []);

  async function clicme() {
    const verify = await VerifyUser(username, password);
    if (verify === true) {
      history.push("/dashboard");
    }
  }
  async function isconnected() {
    const status = await Verify();
    if (status.status) {
      return true;
    }
  }
  /// const imag="https://image.tmdb.org/t/p/w500"+movie['poster_path'];

  return (
    <>
      <Container className="logincenter">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: "20px" }}>
            <img alt="bg-logo-test" src="bengutionlogo.png"></img>
          </div>
          <div>
            <Form className="login">
              <h1>התחברות</h1>
              <Form.Control
                className="controllogin"
                type="text"
                value={username}
                onChange={(e) => {
                  Setusername(e.target.value);
                }}
                required={true}
                placeholder="שם משתמש"
              />
              <Form.Control
                className="controllogin"
                type="password"
                value={password}
                onChange={(e) => {
                  Setpassword(e.target.value);
                }}
                required={true}
                placeholder="סיסמא"
              />
              <Button className="controllogin btn btn-success" onClick={clicme}>
                התחבר
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}
