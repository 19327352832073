import React from "react";
import { useState, useEffect } from "react";

import CountUp from "react-countup";
import Lottie from "lottie-react";
import ani from "../ani.json";
import visitors from "../visitors.json";
import list from "../list.json";
import calendar from "../calendar.json";
import checklist from "../checklist.json";
import wifi from "../wifi.json";
import notepad from "../notepad.json";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  let history = useHistory();
  const { Title, number, lottie, route } = props;
  const [hover, setHover] = useState(0);

  console.log(lottie);
  return (
    <div className="StatisticCube"
    onClick={() => {
      history.push("/Orders")
    }}
    >
      {lottie.bool && (
        <div>
          {Title === "כמות מבקרים החודש" && (
            <div style={{ width: "180px" }}>
            <Lottie
              width={10}
              height={10}
              animationData={wifi}
              loop={true}
              />
              </div>
          )}
          {Title === "הכנסות בשקלים להיום" && (
            <Lottie width={10} height={10} animationData={ani} loop={true} />
          )}
          {Title === "מספר הזמנות החודש" && (
            <div style={{ width: "310px", marginBottom:"-70px" }}>
            <Lottie
              width={10}
              height={10}
              animationData={notepad}
              loop={true}
              />
              </div>
          )}
          {Title === "מספר הזמנות היום" && (
            <div style={{ width: "180px" }}>
            <Lottie
              width={10}
              height={10}
              animationData={calendar}
              loop={true}
              />
              </div>
          )}

        </div>
      )}
      <h4>{Title}</h4>
      <div style={{ display: "flex" }}>
        <span>
          {Title === "הכנסות בשקלים להיום" && "₪"}
          <CountUp duration={1.5} end={parseInt(number)} />{" "}
        </span>
      </div>
    </div>
  );
}
