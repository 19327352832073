import axios from 'axios'

export async function tryifhere() {
    alert("ani po");
}
export async function VerifyUser(username, password) {
    return new Promise((resolve, reject) => {
        var bodyFormData = new FormData()
        bodyFormData.append("username", JSON.stringify({ "username": username, "password": password }))
        axios({
            method: 'post',
            url: "https://bengurion.wdev.co.il/api/admin/management/login",
            data: bodyFormData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(function (response) {
                if (response.data.status == "ok") {
                    localStorage.setItem("token", response.data['token']);
                    //alert("עובדדדד");
                    console.log(response.data);
                    resolve(true);
                }
            }).catch((error) => {
                alert("ההתחברות כשלה");
                resolve(false);
            })
    });
}

export async function Verify() {
    if (localStorage.getItem("token") == null) {
        return { status: false };
    }
    else {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData()
            bodyFormData.append("token", JSON.stringify({ "token": localStorage.getItem("token") }))
            axios({
                method: 'post',
                url: "https://bengurion.wdev.co.il/api/admin/management/verify",
                data: bodyFormData,
                headers: { 'Accept': 'application/json', "Auth": localStorage.getItem("token") }
            })
                .then(function (response) {
                    //console.log(response.data)
                    if (response.data.status == true) {
                        resolve({ status: true, obj: response.data });
                    }
                    else {
                        resolve(false);

                    }
                }).catch((error) => {
                    alert("שגיאה");
                    resolve(false);
                })
        });
    }
}
