import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Button, Row, Container, Col, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";
import { Sendreq } from "../req";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import Sidebar from "./sidebar";
import EditSubAtract from "./EditSubAtract";
registerLocale("he", he);

export default function (props) {
  const trypo = props.connectedobj;
  const [Isconnected, SetIsconnected] = useState(false);
  const [Islogin, SetIslogin] = useState(false);
  const [Dataobject, SetDataobject] = useState([]);
  const [atractType, SetatractType] = useState("");
  const [atractionsList, SetatractionsList] = useState([]);
  const history = useHistory();
  useEffect(async () => {
    var url_string = window.location.href; //
    var url = new URL(url_string);
    var atractType = url.searchParams.get("atractType");
    SetatractType(atractType);

    const List = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/GetAllsubAtracts",
      { atractTYPE: atractType }
    );

    if (List === false) {
      alert("שגיאה בטעינת העמוד");
    } else {
      SetatractionsList(List);
    }
    //console.log(closingdates)
    console.log(List);
  }, [atractionsList]);

  function editAtract(id) {
    history.push({
      pathname: "/EditSubAtract",
      state: {
        atractType: atractType,
        atractid: id,
      },
    });
  }

  if (Islogin) {
    return <h1>טוען...</h1>;
  }

  function ConvertName(str) {
    if (str === "SUBCLOSE") {
      return "סגירת אטרקציה משנית - קבועה";
    }
  }

  async function removefromlist(objectid) {
    var remove = false;
    if (window.confirm("האם אתה בטוח שברצונך למחוק את האטרקציה?")) {
      remove = true;
    }

    if (remove) {
      const List = await Sendreq(
        "https://bengurion.wdev.co.il/api/admin/management/DelSubAtract",
        { objremoveid: objectid }
      );
      SetatractionsList([]);
    }

    /// alert("אני מוחק את - " +objectid );
  }

  function dateformat(date) {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
  }

  function ConvertTypeToText(type) {
    if (type === "0") {
      return "אטרקציה קבועה";
    } else {
      return "אטרקציה זמנית";
    }
  }

  return (
    <>
      {console.log(trypo.data)}

      <Container fluid>
        <Row>
          <br />
          <br />

          <Col xs={12} id="page-content-wrapper">
            <div className="page_heading">
              <h1>אטרקציות משניות</h1>
            </div>
            <div className="contect">
              <div className="mainatractContect">
                <Container>
                  <Col md={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>מחיקה</th>
                          <th>עריכה</th>
                          <th>כותרת אטרקציה</th>
                          <th>סוג אטרקציה</th>
                          <th>תיאור</th>
                          <th>מחיר</th>
                        </tr>
                      </thead>
                      <tbody>
                        {atractionsList &&
                          atractionsList.map((obj) => (
                            <tr>
                              <td style={{ color: "red" }}>
                                <a
                                  className="Pointer"
                                  onClick={() => {
                                    removefromlist(obj.id);
                                  }}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="23.409" height="26.758" viewBox="0 0 23.409 26.758">
                                    <g id="trash-bin_1_" data-name="trash-bin (1)" transform="translate(-32.038)">
                                      <g id="Group_162231" data-name="Group 162231" transform="translate(32.038)">
                                        <path id="Path_62" data-name="Path 62" d="M319.929,190.324l-.975,11.557a.392.392,0,0,0,.781.066l.975-11.557a.392.392,0,0,0-.781-.066Z" transform="translate(-303.958 -180.037)" fill="#ef1212" />
                                        <path id="Path_63" data-name="Path 63" d="M159.743,189.967a.392.392,0,0,0-.358.424l.975,11.557a.392.392,0,0,0,.781-.066l-.975-11.557A.392.392,0,0,0,159.743,189.967Z" transform="translate(-152.729 -180.038)" fill="#ef1212" />
                                        <path id="Path_64" data-name="Path 64" d="M248.5,190.357v11.557a.392.392,0,0,0,.784,0V190.357a.392.392,0,0,0-.784,0Z" transform="translate(-237.187 -180.037)" fill="#ef1212" />
                                        <path id="Path_65" data-name="Path 65" d="M53.639,3.028H47.476V1.65A1.652,1.652,0,0,0,45.826,0H41.66a1.652,1.652,0,0,0-1.65,1.65V3.028H33.846a1.808,1.808,0,1,0,0,3.616h.422l1.466,17.38a2.985,2.985,0,0,0,2.975,2.734H48.777a2.985,2.985,0,0,0,2.975-2.734l.2-2.41a.392.392,0,1,0-.781-.066l-.2,2.41a2.215,2.215,0,0,1-2.193,2.016H38.708a2.215,2.215,0,0,1-2.193-2.016L35.054,6.643h1.161a.392.392,0,0,0,0-.784H33.846a1.024,1.024,0,1,1,0-2.048H53.639a1.024,1.024,0,1,1,0,2.048H38.048a.392.392,0,0,0,0,.784H52.431l-1.1,13.076a.392.392,0,0,0,.358.424h.033a.392.392,0,0,0,.39-.359L53.218,6.643h.422a1.808,1.808,0,1,0,0-3.616Zm-6.948,0h-5.9V1.65A.867.867,0,0,1,41.66.784h4.166a.867.867,0,0,1,.866.866V3.028Z" transform="translate(-32.038)" fill="#ef1212" />
                                      </g>
                                    </g>
                                  </svg>

                                </a>
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    editAtract(obj.id);
                                  }}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20.938" height="20.936" viewBox="0 0 20.938 20.936">
                                    <path id="pen" d="M18.532.813a2.883,2.883,0,0,0-4.074,0l-1.02,1.025L2.582,12.689l-.023.023c-.006.006-.006.012-.012.012-.012.017-.029.034-.04.052s-.006.006-.006.012-.017.029-.029.046-.006.011-.011.017-.012.029-.017.046-.006.006-.006.012L.029,20.151a.567.567,0,0,0,.138.588.58.58,0,0,0,.409.167.68.68,0,0,0,.184-.029L8,18.463c.006,0,.006,0,.012-.006a.209.209,0,0,0,.052-.023.02.02,0,0,0,.012-.006c.017-.012.04-.023.057-.035s.035-.029.052-.04.011-.006.011-.012.017-.011.023-.023L20.094,6.443a2.883,2.883,0,0,0,0-4.074ZM7.814,17.109,3.8,13.1,13.842,3.061l4.01,4.01ZM3.239,14.164l3.5,3.5L1.482,19.42ZM19.281,5.636l-.611.617L14.66,2.242l.617-.616a1.728,1.728,0,0,1,2.443,0l1.567,1.567a1.734,1.734,0,0,1-.006,2.443Zm0,0" transform="translate(0.001 0.029)" fill="#ef1212" />
                                  </svg>

                                </a>
                              </td>
                              <td>{obj.AtractTitle}</td>
                              <td>{ConvertTypeToText(obj.Temporary)}</td>
                              <td>{obj.Description}</td>
                              <td>{obj.Price}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
