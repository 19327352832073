/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";

import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";
import Sidebar from "../Component/sidebar";
import DashboardStatistic from "../Component/Helpers/dashboardStatistic";
import Forms from "../Component/Helpers/forms";
import CouponList from "../Component/Helpers/CouponList";
import { Sendreq } from "../req";
import SwitchToggle from "react-switch";
import DatePicker from "react-datepicker";
import he from "date-fns/locale/he";
import { registerLocale, setDefaultLocale } from "react-datepicker";
registerLocale("he", he);

export default function (props) {
  const [PresentOFF, SetPresentOFF] = useState(0);
  const [Code, SetCode] = useState("");
  const [CouponLists, SetCouponLists] = useState([]);
  const [Toggle, SetToggle] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [FinishDate, SetFinishDate] = useState(new Date());

  function dateformat(date) {
    const dateString = date; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
  }

  function isANumber(str) {
    return !/\D/.test(str);
  }

  useEffect(async () => {
    const Coupon = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/CouponList",
      {}
    );
    SetCouponLists(Coupon);
    ///console.log(Coupon)
  }, [CouponLists]);

  async function RemoveCol(val) {
    const Coupon = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/RemoveCuopon",
      { index: val }
    );

    SetCouponLists([]);
    // alert(val);
  }

  function SetPresentOFF_FN(val) {
    SetPresentOFF(val);
  }

  function SetCode_FN(val) {
    SetCode(val);
  }
  async function insertCoupon() {
    if (Toggle) {
      var diffTime = FinishDate - startDate;
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 0) {
        alert(
          "תאריך הסיום חייב להיות גדול או שווה לתאריך התחילה מתאריך ההתחלה"
        );
        return;
      }

      var today = new Date();
      //console.log(today)
      var diffTime = startDate - today;
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 0) {
        alert("ת. תחילת אירולץ חייב להיות גדול או שווה להיום");
        return;
      }
    }

    if (Code === "" || PresentOFF === "") {
      alert("אחד משדות החובה לא מולאו!");
    } else if (!isANumber(PresentOFF)) {
      alert("אחוז הנחה יכול להכיל מספר בלבד!");
    } else {
      var TotalObject = {
        CouponCode: Code,
        PresentOFF: parseInt(PresentOFF),
        DateRange: Toggle,
        StartDate: startDate,
        FinishDate: FinishDate,
      };

      const AddCoupon = await Sendreq(
        "https://bengurion.wdev.co.il/api/admin/management/AddCoupon",
        TotalObject
      );

      if (AddCoupon === true) {
        alert("הקופון התווסף בהצלחה");
      } else {
        alert("הוספת הקופון נכשלה");
      }
    }
  }

  function SetToggleFN(checked) {
    //  alert(checked);
    SetToggle(checked);
  }

  /// const imag="https://image.tmdb.org/t/p/w500"+movie['poster_path'];

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} id="page-content-wrapper">
            <div className="contect">
              <div className="mainatractContect">
                <Container>
                  <div className="page_heading">
                    <h1>הוספת קוד קופון</h1>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Forms
                        title={"אחוזי הנחה"}
                        clas={""}
                        style={{ width: "280px" }}
                        value={PresentOFF}
                        SetValue={SetPresentOFF_FN}
                      />{" "}
                    </Col>
                    <Col md={4}>
                      <Forms
                        title={"קוד הקופון"}
                        clas={""}
                        style={{ width: "280px" }}
                        value={Code}
                        SetValue={SetCode_FN}
                      />{" "}
                    </Col>
                    <Col md={4}>
                      <Button
                        onClick={insertCoupon}
                        className="SaveAtractbtnCoupon d-flex justify-content-center"
                        variant="success"
                      >
                        הוספת קופון
                      </Button>{" "}
                    </Col>
                  </Row>
                  <Row>{/*  {Toggle && <>} */}</Row>
                  <br />
                  <br />
                  <span className="SubAtract">קופון עם תוקף:</span>{" "}
                  <SwitchToggle onChange={SetToggleFN} checked={Toggle} />
                  <br />
                  <Row>
                    {Toggle && (
                      <Col md={4}>
                        <label style={{ marginBottom: "10px" }}>מתאריך</label>
                        <DatePicker
                          locale="he"
                          className={"form-control"}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </Col>
                    )}
                    {Toggle && (
                      <Col md={4}>
                        <label style={{ marginBottom: "10px" }}>עד תאריך</label>
                        <DatePicker
                          locale="he"
                          className={"form-control"}
                          dateFormat="dd/MM/yyyy"
                          selected={FinishDate}
                          onChange={(date) => SetFinishDate(date)}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <div className="CouponList">
                      <h2>רשימת קופונים</h2>
                    </div>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div className="VisitorTypeDIV1">
                        <span>קוד קופון</span>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="VisitorTypeDIV1">
                        <span>אחוז</span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="VisitorTypeDIV1">
                        <span>תאריכים</span>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="VisitorTypeDIV1">
                        <span>מחק</span>
                      </div>
                    </Col>
                  </Row>
                  {CouponLists.map((item, index) => (
                    <CouponList key={index} RemoveCol={RemoveCol} {...item} />
                  ))}
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
