import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import { Connected } from "../Component/Connected";
import Sidebar from "../Component/sidebar";
import DashboardStatistic from "../Component/Helpers/dashboardStatistic";
import { Sendreq } from "../req";
import ani from "./ani.json";
import visitors from "./visitors.json";
import list from "./list.json";
import checklist from "./checklist.json";
import calendar from "./calendar.json";
import Shortcut from "./Shortcuts";

export default function (props) {
  const [StartObject, SetStartObject] = useState([]);
  const [totalToday, setTotalToday] = useState(0);


  useEffect(async () => {
    const obj = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/BasicDeshboard",
      {}
    );
    const ordersToday = obj.OrdersToday.reduce(
      (a, b) => a + parseInt(b.Amount),
      0
    );
    setTotalToday(ordersToday);
    console.log(ordersToday);
    SetStartObject(obj);
    //console.log(obj);
  }, []);

  
  return (
    <>
      <Container fluid>
        <Row >
          <Col xs={12} id="page-content-wrapper">
            <div className="contect">
              <Container fluid>
                <div className="page_heading">
                  <h1>מערכת ניהול בן גוריון</h1>
                </div>
                <div>
                  <Shortcut />
                </div>
                <Row style={{ marginTop: "120px" }}>
                    <Col md={3}>
                      <DashboardStatistic
                        lottie={{ bool: true, lottie: ani }}
                        Title={"הכנסות בשקלים להיום"}
                        number={totalToday}
                      />
                    </Col>
          
                  <Col md={3}>
                    <DashboardStatistic
                      lottie={{ bool: true, lottie: visitors }}
                      Title={"כמות מבקרים החודש"}
                      number={StartObject.VisitorsMonthly}
                    />
                  </Col>
                  <Col md={3}>
                    <DashboardStatistic
                      lottie={{ bool: true, lottie: checklist }}
                      Title={"מספר הזמנות החודש"}
                      number={StartObject.OrdersNumber}
                    />
                  </Col>
                  <Col md={3}>
                    <DashboardStatistic
                      lottie={{ bool: true, lottie: calendar }}
                      Title={"מספר הזמנות היום"}
                      number={StartObject.TodayOrders}
                    />
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
