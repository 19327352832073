import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import {
  Button,
  Row,
  Container,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import Sidebar from "../Component/sidebar";
import TimeInput from "./Helpers/timeinput";
import OpenHours from "./Helpers/DaysHours";
import Forms from "./Helpers/forms";
import VisitorsTypes from "./Helpers/VisitorsTypes";
import { Sendreq, SendFile } from "../req";
import SwitchToggle from "react-switch";

export default function (props) {
  const array = [
    {
      id: "29",
      AtractID: "34",
      ENLDayName: "Sunday",
      HEDayName: "יום ראשון",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "30",
      AtractID: "34",
      ENLDayName: "Monday",
      HEDayName: "יום שני",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "31",
      AtractID: "34",
      ENLDayName: "Tuesday",
      HEDayName: "יום שלישי",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "32",
      AtractID: "34",
      ENLDayName: "Wednesday",
      HEDayName: "יום רביעי",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "33",
      AtractID: "34",
      ENLDayName: "Thursday",
      HEDayName: "יום חמישי",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "34",
      AtractID: "34",
      ENLDayName: "Friday",
      HEDayName: "יום שישי",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
    {
      id: "35",
      AtractID: "34",
      ENLDayName: "Saturday",
      HEDayName: "יום שבת",
      StartTime: "09:00:00",
      EndTime: "18:00:00",
    },
  ];
  const { id } = useParams();
  const [VisitorsTypeArray, SetVisitorsTypeArray] = useState([]);
  const [Visitor, SetVisitor] = useState("");
  const [VisitorPrice, SetVisitorPrice] = useState("");
  const [TitleVal, SetTitleVal] = useState("");
  const [Textura, SetTextura] = useState("");
  const [EnterPrice, SetEnterPrice] = useState(0);
  const [DaysInWeek, SetDaysInWeek] = useState(array);
  const [VisitorsTypeTEXT, SetVisitorsTypeTEXT] = useState("");
  const [VisitorsPricesTEXT, SetVisitorsPricesTEXT] = useState("");
  const [MainPhoto, SetMainPhoto] = useState(
    "https://st2.depositphotos.com/3765753/5349/v/450/depositphotos_53491489-stock-illustration-example-rubber-stamp-vector-over.jpg"
  );
  const [VisitorsArray, SetVisitorsArray] = useState([]);
  const [Attractid, SetAttractid] = useState();
  const [btnloading, Setbtnloading] = useState(false);
  const [atractType, SetatractType] = useState("");
  const [Picture, SetPicture] = useState(null);
  const [Toggle, SetToggle] = useState(false);
  const [ToggleCardsType, SetToggleCardsType] = useState(false);
  const [Continuousentry, SetContinuousentry] = useState(false);
  const [ImmediateEntry, SetImmediateEntry] = useState(false);
  const [ToggleStock, SetToggleStock] = useState(false);

  const [SubAtractStock, SetSubAtractStock] = useState(0);
  String.prototype.isNumber = function () {
    return /^\d+$/.test(this);
  };

  function SetImmediateEntryFN(val) {
    SetImmediateEntry(val);
  }

  function SetContinuousentryFN(val) {
    SetContinuousentry(val);
  }

  function SetToggleCardsTypeFN(val) {
    SetToggleCardsType(val);
  }
  function SetToggleStockFN(val) {
    SetToggleStock(val);
  }

  function SetSubAtractStockFn(val) {
    SetSubAtractStock(val);
  }

  function DelImage() {
    SetPicture(null);
    SetMainPhoto(
      "https://st2.depositphotos.com/3765753/5349/v/450/depositphotos_53491489-stock-illustration-example-rubber-stamp-vector-over.jpg"
    );

    // alert("Here!");
  }

  useEffect(async () => {
    ///console.log(array[0]);
    /*   var url_string = window.location.href; 
    var url = new URL(url_string);
    var atractType = url.searchParams.get("atractType");
    SetatractType(atractType);

    const atract=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractJson",{atractType:atractType});
    console.log(atract);
    SetTitleVal(atract.BasicData['AtractTitle']);
    SetTextura(atract.BasicData['Description']);
    SetEnterPrice(atract.BasicData['EnterPricePerHour']);
    SetVisitorsArray(atract.CustomerType);
    SetDaysInWeek(atract.OpenHours);
    SetAttractid(atract.BasicData.id); */
  }, []);

  const onChangePicture = (e) => {
    /// alert(e.target.files[0]);
    if (e.target.files[0]) {
      SetPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        SetMainPhoto(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {}, [VisitorsArray]);

  function SetVisitorsTypeTEXT_FN(val) {
    SetVisitorsTypeTEXT(val);
  }

  function SetVisitorsPricesTEXT_FN(val) {
    SetVisitorsPricesTEXT(val);
  }

  function SetTime(val) {
    var temp = DaysInWeek;
    temp[val["index"]][val["Type"]] = val[val["Type"]];
    //SetDaysInWeek()
  }
  function SetValueEnterPrice(val) {
    SetEnterPrice(val);
  }
  function SetValue(val) {}
  function btnCliked() {
    if (VisitorsTypeTEXT === "" || VisitorsPricesTEXT === "") {
      alert("אחד משדות החובה לא קימות");
    } else if (VisitorsPricesTEXT.match(/^[0-9]+$/) == null) {
      alert("שדה המחיר חייב להיות מספר!");
    } else {
      var exist = VisitorsArray.find((x) => x.VisitorType === VisitorsTypeTEXT);
      if (exist) {
        alert("כבר קיים שדה מסוג זה. ראשית יש למחוק אותו .");
      } else {
        SetVisitorsArray((VisitorsArray) => [
          ...VisitorsArray,
          { Type_Name: VisitorsTypeTEXT, Price: VisitorsPricesTEXT },
        ]);
      }
    }
  }

  function RemoveCol(name) {
    SetVisitorsArray(VisitorsArray.filter((item) => item.Type_Name !== name));
  }

  async function SendToApi() {
    Setbtnloading(true);
    let uploadimage = null;
    if (Picture !== null) {
      uploadimage = await SendFile(
        "https://bengurion.wdev.co.il/api/admin/management/FileUpload",
        Picture
      );
    }
    var TotalObject = {
      belongatract: 34,
      AtractTitle: TitleVal,
      Description: Textura,
      EnterPrice: EnterPrice,
      DaysInWeek: DaysInWeek,
      temporary: Toggle,
      Image: uploadimage,
      VisitorsArray: VisitorsArray,
      StockPerHour: SubAtractStock,
      CardType: ToggleCardsType,
      UsingStock: ToggleStock,
      Continuousentry: Continuousentry,
      ImmediateEntry: ImmediateEntry,
    };
    const addAtract = await Sendreq(
      "https://bengurion.wdev.co.il/api/admin/management/AddsubAtract",
      TotalObject
    );

    if (addAtract === false) {
      alert("שגיאה.");
      return;
    }

    alert("הפרטים עודכנו בהצלחה!");
    Setbtnloading(false);
    window.location.reload();
  }

  function SetToggleFN(checked) {
    //  alert(checked);
    SetToggle(checked);
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="page_heading">
              <h1>הוספת אטרקציה משנית</h1>
            </div>
          </Col>
          <Col xs={12} id="page-content-wrapper">
            <div className="mainatractContect">
              <Container>
                <Row>
                  <Col md={12}>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ margin: "1rem" }} className="SubAtract">
                        אטרקציה זמנית:
                      </span>{" "}
                      <SwitchToggle onChange={SetToggleFN} checked={Toggle} />
                    </div>
                  </Col>
                </Row>
                <h1 className="h1mainatract">פירוט אטרקציה משנית</h1>
                <FloatingLabel
                  controlId="floatingInput"
                  label="כותרת פעילות"
                  className="mb-3 atractinput1"
                >
                  <Form.Control
                    value={TitleVal}
                    onChange={(e) => {
                      SetTitleVal(e.target.value);
                    }}
                    type="text"
                    placeholder="name@example.com"
                  />
                </FloatingLabel>
                <FloatingLabel label="תוכן הפעילות">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    className="atractinput1"
                    style={{ height: "200px" }}
                    value={Textura}
                    onChange={(e) => {
                      SetTextura(e.target.value);
                    }}
                  />
                </FloatingLabel>
                {Toggle === false ? (
                  <h1 className="h1mainatract">שעות פעילות המתחם</h1>
                ) : (
                  false
                )}
                {Toggle === false &&
                  DaysInWeek.map((item, index) => (
                    <OpenHours
                      key={index}
                      num={index}
                      day={item}
                      SetTime={SetTime}
                    />
                  ))}
                {/* 
                                    <OpenHours num={0} day={DaysInWeek[0]} SetTime={SetTime} />
                                    <OpenHours num={1} day={DaysInWeek[1]} SetTime={SetTime} />
                                    <OpenHours num={2} day={DaysInWeek[2]} SetTime={SetTime} />
                                    <OpenHours num={3} day={DaysInWeek[3]} SetTime={SetTime} />
                                    <OpenHours num={4} day={DaysInWeek[4]} SetTime={SetTime} />
                                    <OpenHours num={5} day={DaysInWeek[5]} SetTime={SetTime} />



                                    <OpenHours num={6} day={DaysInWeek[6]} SetTime={SetTime} /> */}
                <h1 className="h1mainatract">תמונה ראשית</h1>
                <Col md={12}>
                  <img
                    onClick={DelImage}
                    className="DelImage"
                    src="delete.png"
                  />
                  <img className="ShowMainImage" src={MainPhoto} />
                </Col>
                <h3>העלאת תמונה חדשה</h3>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Control
                    onChange={onChangePicture}
                    id="files"
                    className="hidden"
                    type="file"
                    multiple
                  />
                  <label className="UploadImage" for="files">
                    בחר תמונה ראשית לאטרקציה
                  </label>
                </Form.Group>
                <span className="Multi">מלאי קיים לאטרקציה : </span>
                <SwitchToggle
                  onChange={SetToggleStockFN}
                  checked={ToggleStock}
                />
                <br />
                {ToggleStock && (
                  <h1 className="h1mainatract">מלאי כרטיסים לאטרקציה</h1>
                )}
                {ToggleStock && (
                  <Forms
                    clas={"atractinput1"}
                    title={"מלאי כרטיסים לאטרקציה"}
                    value={SubAtractStock}
                    SetValue={SetSubAtractStockFn}
                  />
                )}
                <br />
                <br />
                <span className="Multi">מרובה סוגי כרטיסים : </span>
                <SwitchToggle
                  onChange={SetToggleCardsTypeFN}
                  checked={ToggleCardsType}
                />
                <br />
                {ToggleCardsType ? (
                  <h1 className="h1mainatract">הגדרת מבקרים</h1>
                ) : (
                  <h1 className="h1mainatract">מחיר כרטיס</h1>
                )}
                {ToggleCardsType === false ? (
                  <Forms
                    clas={"atractinput1"}
                    title={"מחיר הכרטיס בשקלים כולל מעמ"}
                    value={EnterPrice}
                    SetValue={SetValueEnterPrice}
                  />
                ) : (
                  <Row>
                    <Col md={4}>
                      <Forms
                        title={"סוגי מבקרים"}
                        clas={""}
                        style={{ width: "280px" }}
                        value={VisitorsTypeTEXT}
                        SetValue={SetVisitorsTypeTEXT_FN}
                      />
                    </Col>
                    <Col md={4}>
                      <Forms
                        title={"מחיר לפי מבקר"}
                        clas={""}
                        style={{ width: "280px" }}
                        value={VisitorsPricesTEXT}
                        SetValue={SetVisitorsPricesTEXT_FN}
                      />
                    </Col>
                      <Col md={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center"
                      }}
                      >
                      <img
                        onClick={btnCliked}
                        className="addTypeBTN"
                        src="../pluse.png"
                      ></img>
                    </Col>
                  </Row>
                )}
                {VisitorsArray.map((item, index) => (
                  <VisitorsTypes
                    key={index}
                    index={index}
                    typename={item.Type_Name}
                    RemoveCol={RemoveCol}
                    price={item.Price}
                  />
                ))}
                <h1 className="h1mainatract">הגדרות נוספות</h1>
                <br />
                <br />
                <span className="Multi">כניסה רציפה לאטרקציה : </span>
                <SwitchToggle
                  onChange={SetContinuousentryFN}
                  checked={Continuousentry}
                />
                <br />
                <br />
                <span className="Multi">
                  כניסה לאטרקציה בדיוק בשעת הכניסה :{" "}
                </span>
                <SwitchToggle
                  onChange={SetImmediateEntryFN}
                  checked={ImmediateEntry}
                />
              </Container>
            </div>
            <Button
              onClick={SendToApi}
              disabled={btnloading}
              className="SaveAtractbtn d-flex justify-content-center"
              variant="success"
            >
              {btnloading ? "טוען.." : "הוסף אטרקציה"}
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
}
