import React from "react";
import { useState } from "react";

export default function SubAtractionStatistic(props) {
  const { Title, AvlStock, UseStock } = props;
  return (
    <div className="StatisticCube2">
      <h4>{Title}</h4>
      <span>
        {UseStock}/{AvlStock}
      </span>
      <p>
        <span>
          סה"כ אחוזי תפוסה -{" "}
          {((parseInt(UseStock) / parseInt(AvlStock)) * 100).toFixed(0)}%
        </span>
      </p>
    </div>
  );
}
