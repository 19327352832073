import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';



export default function(props){


        const {CouponCode,PresentOFF,key,index,RemoveCol,id,LimitTime,StartDate,FinishDate}=props;
        ////console.log(props);


        function dateformat(date){
            const dateString = date; // ISO8601 compliant dateString
            const D = new Date(dateString);
          
            return D.getDate()+"/"+ (D.getMonth() + 1)+"/"+D.getFullYear();
          
          
          }





    return (
        <>
        <Row>




        <Col md={4}><div className="VisitorTypeDIV"><span>{CouponCode}</span></div></Col>
        <Col md={2}><div className="VisitorTypeDIV"><span>{PresentOFF}%</span></div></Col>
        {parseInt(LimitTime) === 1 ? <Col md={4}> <div className="VisitorTypeDIV"><span>{dateformat(StartDate)}  עד  {dateformat(FinishDate)}</span> </div></Col> :<Col md={4}><div className="VisitorTypeDIV"><span>ללא הגבלה</span></div></Col> }
        <Col md={2}><label onClick={()=>{RemoveCol(id)}} className="Xbtn">X</label></Col>
        
        </Row>
        </>


     
    );

}