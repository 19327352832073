import { react, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { tryifhere, Verify } from "./verifysystem";
import Login from "./Component/Login";
import PrivateRoute from "./Component/PrivateRoute";
import Connected from "./Component/Connected";
import Closedates from "./Component/Closedates";
import CustomersType from "./Component/CustomersType";
import Dashboard from "./Component/Dashboard";
import Mainattraction from "./Component/Mainattraction";
import Coupon from "./Component/Coupon";
import AddsubAtract from "./Component/Addsubatract";
import Constraints from "./Component/Constraints";
import ConstraintsList from "./Component/ConstraintsList";
import SubAttracrionList from "./Component/SubatractionList";
import Orders from "./Component/Orders";
import EditSubAtract from "./Component/EditSubAtract";
import Statistic from "./Component/Statistics";
import EditStockByDate from "./Component/EditStockByDate";
import Welcome from "./Component/Welcome";
import RemoveStock from "./Component/RemoveStock";
import HomePage from "./Component/HomePage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Component/sidebar";

function App() {
  const [thisDate, setThisDate] = useState(
    new Date().toLocaleDateString("he-IL")
  );
  const [thisWeather, setThisWeather] = useState("");
  const [thisIcon, setThisIcon] = useState("");
  const getWeather = async () => {
    // You can await here
    const data = await axios.get(
      "https://fcc-weather-api.glitch.me/api/current?lat=30&lon=34"
    );
    if (data.status === 200) {
      console.log(data.data.weather[0].icon, "datamanin");
      setThisIcon(data.data.weather[0]);
      setThisWeather(Math.round(data.data.main.temp));
    }
    return;
  };
  useEffect(() => {
    // setThisWeather(getWeather());
    getWeather();
    console.log("getWeather useeffect");
  }, []);
  return (
    <div className="App">
      <section className="App-header">
        <div></div>
        <div></div>
        <div className="stats">
          <p>התאריך של היום: {thisDate} </p>
          <p>מזג האוויר בצריף: {`${thisWeather}°C`}</p>
          <img
            style={{
              transform: "translate(0,-7px)",
            }}
            src={thisIcon.icon}
            alt="weather"
          />
        </div>
      </section>

      <Router>
        <div style={{ display: "grid", gridTemplateColumns: "300px auto" }}>
          <div>
            <Sidebar />
          </div>
          <div style={{}}>
            <>
              <Switch>
                <Route exact path="/">
                  <Login />
                </Route>
                <Route path="/Login">
                  <Login />
                </Route>
                <PrivateRoute
                  path="/protected"
                  component={Connected}
                ></PrivateRoute>
                <PrivateRoute
                  path="/EditSubAtract"
                  component={EditSubAtract}
                ></PrivateRoute>
                <PrivateRoute
                  path="/closedates"
                  component={Closedates}
                ></PrivateRoute>
                <PrivateRoute
                  path="/customerstype"
                  component={CustomersType}
                ></PrivateRoute>
                <PrivateRoute
                  path="/dashboard"
                  component={Dashboard}
                ></PrivateRoute>
                <PrivateRoute
                  path="/Mainattraction"
                  component={Mainattraction}
                ></PrivateRoute>
                <PrivateRoute
                  path="/Addcoupon"
                  component={Coupon}
                ></PrivateRoute>
                <PrivateRoute
                  path="/Addsubatraction"
                  component={AddsubAtract}
                ></PrivateRoute>
                <PrivateRoute
                  path="/Constraints"
                  component={Constraints}
                ></PrivateRoute>
                <PrivateRoute
                  path="/ConstraintsList"
                  component={ConstraintsList}
                ></PrivateRoute>
                <PrivateRoute
                  path="/SubAttracrionList"
                  component={SubAttracrionList}
                ></PrivateRoute>
                <PrivateRoute path="/Orders" component={Orders}></PrivateRoute>
                <PrivateRoute
                  path="/Statistic"
                  component={Statistic}
                ></PrivateRoute>
                <PrivateRoute
                  path="/EditStockByDate"
                  component={EditStockByDate}
                ></PrivateRoute>
                <PrivateRoute
                  path="/Welcome"
                  component={Welcome}
                ></PrivateRoute>
                <PrivateRoute
                  path="/RemoveStock"
                  component={RemoveStock}
                ></PrivateRoute>
              </Switch>
            </>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
